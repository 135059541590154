import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const Privacy = (props) => (
    <Layout>
        <Helmet>
            <title>NEA Xplore</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="privacy">
                <div className="inner">
                    <header className="major">
                        <h1>Datenschutzerkl&auml;rung &ndash; Ihre Daten sind bei uns sicher</h1>
                    </header>
                    <p>Datenschutz ist Vertrauenssache und Ihr Vertrauen ist uns wichtig. Wir respektieren Ihre Privatsph&auml;re. Der Schutz und die gesetzeskonforme Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten ist daher f&uuml;r uns ein wichtiges Anliegen. Damit Sie sich bei dem Besuch unserer Webseiten sicher f&uuml;hlen, beachten wir bei der Verarbeitung Ihrer personenbezogenen Daten streng die gesetzlichen Bestimmungen und m&ouml;chten Sie hier &uuml;ber unsere Datenerhebung und Datenverwendung informieren.</p>
                    <p><u>Verantwortlicher f&uuml;r die Datenverarbeitung i.S.d. Art. 4 Nr. 7 DSGVO ist:</u></p>
                    <p><u>1. Verantwortliche Stelle:</u></p>
                    <p>NEA X GmbH<br />Am Kraftversorgungsturm 5<br />52070 Aachen<br />&nbsp;<br />Email: support@neaxplore.de<br />&nbsp;<br />Gesch&auml;ftsf&uuml;hrer: Dr. Patrick Beaujean / Jens Meyer-Beeck</p>
                    <p><u>2. Datenschutzbeauftragter</u></p>
                    <p>Wenn Sie Fragen zum Thema Datenschutz haben, kontaktieren Sie uns bitte.</p>
                    <p>Herr Stefan Kleinermann<br />Kleinermann &amp; Sohn GmbH<br />Max-Planck-Str. 9<br />52499 Baesweiler</p>
                    <p>Tel.: (02401) 6054-0<br />E-Mail: <u><a href="mailto:dsb@das-datenschutz-team.de">dsb@das-datenschutz-team.de</a></u></p>
                    <p><u>Umfang und Zweck der Datenverarbeitung</u></p>
                    <p><u>1. Anonyme Datenerhebung</u></p>
                    <p>Sie k&ouml;nnen unsere Webseite besuchen, ohne Angaben zu Ihrer Person zu machen. Auch speichern wir keine sog. &bdquo;Serverlogfiles&ldquo; beim Besuch unserer Webseite.</p>
                    <p>Das Webhosting erfolgt durch einen von uns beauftragten Webhosting-Anbieter. Der Serverstandort ist in Frankfurt. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Bereitstellung unseres Onlineangebotes). Mit dem Anbieter haben wir einen Auftragsverarbeitungsvertrag gem. Art. 28 DSGVO geschlossen.</p>
                    <p><u>2. Erhebung und Verarbeitung personenbezogener Daten</u></p>
                    <p>Sofern Sie einen Service unseres Unternehmens &uuml;ber unsere Webseite in Anspruch nehmen m&ouml;chten und/oder einen Account f&uuml;r das NEA X Portal erstellen, ist eine Verarbeitung Ihrer personenbezogenen Daten erforderlich. Sofern f&uuml;r die Verarbeitung von personenbezogenen Daten keine gesetzliche Grundlage besteht, werden wir hierzu eine Einwilligung der betroffenen Person einholen. Dieses wird an entsprechenden Stellen ersichtlich sein. Rechtsgrundlage f&uuml;r Verarbeitungsvorg&auml;nge, bei denen wir eine Einwilligung von Ihnen f&uuml;r Verarbeitungszwecke einholen, ist Art. 6 Abs. 1 lit. a DSGVO. Ist die Verarbeitung personenbezogener Daten zur Erf&uuml;llung eines Vertrags oder einer Vertragsanbahnung erforderlich (z. B. f&uuml;r die Erbringung unserer Leistungen, oder bei Service-Anfragen /-Auftr&auml;gen, Anfragen zu unseren Produkten und Leistungen), so erfolgt die Verarbeitung nach Art. 6 Abs. 1 lit. b DSGVO.</p>
                    <p>Die von Ihnen &uuml;bermittelten personenbezogenen Daten wie Name, Firma, Anschrift, E-Mail und Telefon speichern und verwenden wir zum Zwecke Durchf&uuml;hrung unserer Leistungen und der individuellen Kommunikation mit Ihnen in &Uuml;bereinstimmung mit der Datenschutzgrundverordung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG).</p>
                    <p>Rechtsgrundlage f&uuml;r die Verarbeitung der Daten, die im Zuge einer E-Mail &uuml;bermittelt werden, ist Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;b DSGVO.</p>
                    <p><u>3. Registrierung</u></p>
                    <p>Auf unserer Webseite haben Sie die M&ouml;glichkeit sich unter Angabe personenbezogener Daten f&uuml;r das NEA X Portal (<u><a href="https://portal.neaxplore.com/">https://portal.neaxplore.com</a></u>) zu registrieren. Die im Zuge dieser Registrierung eingegebenen Daten sind aus der Eingabemaske des Registrierungsformulars ersichtlich (Vorname, Nachname, Email-Adresse).</p>
                    <p>Mit Ihrer Registrierung speichern wir Ihre IP-Adresse, das Datum sowie die Uhrzeit der Registrierung. Dies dient in dem Fall, dass ein Dritter Ihre E-Mail-Adresse missbraucht und ohne Ihr Wissen unseren Dienst missbraucht, als Absicherung unsererseits. Weitere Daten werden unsererseits nicht erhoben. Eine Weitergabe an Dritte erfolgt nicht, sofern keine gesetzliche Pflicht zur Weitergabe besteht oder die Weitergabe der Strafverfolgung dient.</p>
                    <p>Sie haben die M&ouml;glichkeit, die bei der Registrierung angegebenen personenbezogenen Daten jederzeit zu &auml;ndern oder durch uns vollst&auml;ndig l&ouml;schen zu lassen unter Beachtung von Aufbewahrungsfristen aufgrund von Gesetzen und/oder der Abgabenordnung.</p>
                    <p>Ihre Registrierung ist f&uuml;r das Bereithalten bestimmter Inhalte und Leistungen auf unserem Portal (<u><a href="https://portal.neaxplore.com/">https://portal.neaxplore.com</a></u>) erforderlich. Rechtsgrundlage f&uuml;r die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO. Dient die Registrierung der Erf&uuml;llung eines Vertrages, dessen Vertragspartei der Nutzer ist oder der Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung der Daten Art. 6 Abs. 1 lit. b DSGVO. Die Daten werden gel&ouml;scht, sobald sie f&uuml;r die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind.</p>
                    <p><u>4. Cookies</u></p>
                    <p>Beim Besuch unserer Webseite kommen keine einwilligungspflichten &bdquo;Cookies&ldquo; zum Einsatz. Wir verwenden keine Analyse-/Tracking-Tools.</p>
                    <p><u>5. Betroffenenrechte</u></p>
                    <p>Als von der Datenverarbeitung betroffene Person haben Sie das Recht auf Auskunft (Art. 15 DSGVO), Berichtigung (Art. 16 DSGVO), Datenl&ouml;schung (Art. 17 DSGVO) und Einschr&auml;nkung der Verarbeitung (Art. 18 DSGVO) sowie Daten&uuml;bertragbarkeit (Art. 20 DSGVO). Wir verzichten auf automatische Entscheidungsfindung oder ein Profiling (Art. 22 DSGVO).</p>
                    <p>Zudem haben Sie gem. Art. 21 DSGVO das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund einer Interessenabw&auml;gung (Art. 6 Abs. 1 lit. f DSGVO) erfolgt, Widerspruch einzulegen. Dies ist insbesondere dann der Fall, wenn die Datenverarbeitung nicht zur Erf&uuml;llung eines Vertrages erforderlich ist. Sofern Sie von Ihrem Widerspruchsrecht Gebrauch machen, bitten wir Sie um die Darlegung der Gr&uuml;nde. Wir werden Ihre personenbezogenen Daten dann nicht mehr verarbeiten, es sei denn, wir k&ouml;nnen Ihnen gegen&uuml;ber nachweisen, dass zwingende schutzw&uuml;rdige Gr&uuml;nde an der Datenverarbeitung Ihre Interessen und Rechte &uuml;berwiegen. Ihren Widerspruch richten Sie bitte an die oben angegebene Kontaktadresse des Verantwortlichen.</p>
                    <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verst&ouml;&szlig;t oder Ihre datenschutzrechtlichen Anspr&uuml;che sonst in einer Weise verletzt worden sind, k&ouml;nnen Sie sich an die Aufsichtsbeh&ouml;rde Ihres &uuml;blichen Aufenthaltsortes, Ihres Arbeitsplatzes oder unseres Unternehmenssitzes (Die Landesbeauftragte f&uuml;r Datenschutz und Informationsfreiheit Nordrhein-Westfalen) wenden. (Art. 77 DSGVO).</p>
                    <p><u><u>Widerrufsrecht (Art. 7 Abs. 3 DSGVO)</u></u></p>
                    <p><u>Haben Sie in die Verarbeitung Ihrer personenbezogenen Daten durch uns eingewilligt, haben Sie das Recht, die Einwilligung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht ber&uuml;hrt. Ebenso unber&uuml;hrt bleibt eine weitere Verarbeitung dieser Daten aufgrund einer anderen Rechtsgrundlage, etwa zur Erf&uuml;llung rechtlicher Verpflichtungen (z.B. gesetzliche Aufbewahrungsfristen).</u></p>
                    <p>Zur Aus&uuml;bung der Betroffenenrechte wenden Sie sich bitte an die oben genannte Stelle. Anfragen, die elektronisch eingereicht werden, werden in der Regel elektronisch beantwortet. Die nach der DSGVO zur Verf&uuml;gung zu stellenden Informationen, Mitteilungen und Ma&szlig;nahmen einschlie&szlig;lich &bdquo;der Aus&uuml;bung der Betroffenenrechte&ldquo; werden grunds&auml;tzlich unentgeltlich erbracht. Lediglich im Fall von offenkundig unbegr&uuml;ndeten oder exzessiven Antr&auml;gen sind wir berechtigt, f&uuml;r die Bearbeitung ein angemessenes Entgelt zu erheben oder von einem T&auml;tigwerden abzusehen (siehe Art. 12 Ab. 5 DSGVO).&nbsp;</p>
                    <p>Bestehen begr&uuml;ndete Zweifel an ihrer Identit&auml;t, d&uuml;rfen wir, zum Zwecke der Identifizierung, zus&auml;tzliche Informationen von Ihnen verlangen. Ist uns eine Identifizierung nicht m&ouml;glich, sind wir berechtigt, die Bearbeitung ihrer Anfrage zu verweigern. &Uuml;ber eine fehlende M&ouml;glichkeit zur Identifikation werden wir Sie &ndash; soweit m&ouml;glich &ndash; gesondert benachrichtigen. (siehe Art. 12 Abs. 6 und Art. 11 DSGVO).</p>
                    <p>Auskunfts- und Informationsbegehren werden in der Regel unverz&uuml;glich, innerhalb eines Monats nach Eingang der Anfrage bearbeitet. Die Frist kann um weitere zwei Monate verl&auml;ngert werden, soweit dies unter Ber&uuml;cksichtigung der Komplexit&auml;t und/oder der Anzahl der Anfragen erforderlich ist; im Fall einer Fristverl&auml;ngerung werden wir Sie innerhalb eines Monats nach Eingang ihrer Anfrage &uuml;ber die Gr&uuml;nde f&uuml;r die Verz&ouml;gerung informieren. Sollten wir auf einen Antrag hin nicht t&auml;tig werden, werden wir Sie unverz&uuml;glich innerhalb eines Monats nach Eingang des Antrags &uuml;ber die Gr&uuml;nde hierf&uuml;r unterrichten und sie &uuml;ber die M&ouml;glichkeit, bei einer Aufsichtsbeh&ouml;rde Beschwerde einzulegen oder einen gerichtlichen Rechtsbehelf in Anspruch zu nehmen, informieren. (siehe Art. 12 Abs. 3 und Abs. 4 DSGVO).</p>
                    <p>Bitte beachten Sie, dass Sie Ihre Betroffenenrechte nur im Rahmen von der Union oder den Mitgliedsstaaten vorgesehener Einschr&auml;nkungen und Beschr&auml;nkungen aus&uuml;ben k&ouml;nnen. (Art. 23 DSGVO).</p>
                    <p><u>6. Datensicherheit</u></p>
                    <p>Ihre personenbezogenen Daten werden bei der Registrierung auf unserer Webseite verschl&uuml;sselt mittels TLS &uuml;ber das Internet &uuml;bertragen. Wir sichern unsere Website und sonstigen Systeme durch technische und organisatorische Ma&szlig;nahmen gegen Verlust, Zerst&ouml;rung, Zugriff, Ver&auml;nderung oder Verbreitung Ihrer Daten durch unbefugte Personen. Der Zugang zu Ihrem Benutzerkonto ist nur nach Eingabe Ihres pers&ouml;nlichen Passwortes m&ouml;glich. Sie sollten Ihre Zugangsinformationen stets vertraulich behandeln und das Browserfenster schlie&szlig;en, wenn Sie die Kommunikation mit uns beendet haben, insbesondere wenn Sie einen &ouml;ffentlichen Computer, oder den Computer gemeinsam mit anderen nutzen.</p>
                    <p><u>7. L&ouml;schung und Einschr&auml;nkung (Sperrung) von personenbezogenen Daten</u></p>
                    <p>Die L&ouml;schung und Einschr&auml;nkung (Sperrung) Ihrer personenbezogenen Daten erfolgt nach Entfall der Zweckbindung, sofern sie nicht mehr zur Vertragserf&uuml;llung oder Vertragsanbahnung erforderlich sind, unter Beachtung von Aufbewahrungsfristen aufgrund von Gesetzen und/oder der Abgabenordnung.</p>
                    <p><u>8. Kontaktm&ouml;glichkeit</u></p>
                    <p>Wir bieten Ihnen auf unserer Webseite die M&ouml;glichkeit, mit uns per E-Mail in Verbindung zu treten. In diesem Fall werden die von Ihnen gemachten Angaben zum Zwecke der Bearbeitung Ihrer Kontaktaufnahme gespeichert. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte Interesse an der Verarbeitung der Daten. Eine Weitergabe an Dritte erfolgt nicht. Rechtsgrundlage f&uuml;r die Verarbeitung der Daten ist Art. 6 Abs. 1 lit. f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Die Daten werden gel&ouml;scht, sobald sie f&uuml;r die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind und keine Aufbewahrungsfristen entgegenstehen.</p>
                    <p><u>9. &Auml;nderung und Aktualisierung der Datenschutzerkl&auml;rung</u></p>
                    <p>Aktualisierungen an unserer Datenschutzerkl&auml;rung k&ouml;nnen sich aufgrund &Auml;nderungen der von uns durchgef&uuml;hrten Datenverarbeitungen, oder von Gesetzes&auml;nderungen, Gerichtsurteilen, &Auml;nderung von Kontaktinformationen unseres Unternehmens, etc. ergeben. Daher bitten wir Sie, sich regelm&auml;&szlig;ig &uuml;ber den Inhalt unserer Datenschutzerkl&auml;rung zu informieren.</p>
                    <p><i>Stand der Datenschutzerkl&auml;rung: 08.07.2022</i></p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Privacy